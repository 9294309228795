import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import firebase from './infocomponents/firebase';
import { Link } from 'react-router-dom';

function NavComponent() {
	const [ userName, setUser ] = useState('LOGIN');
	const [ loginStatus, setStatus ] = useState('');

	function signOutUser() {
		firebase
			.auth()
			.signOut()
			.then(function() {
				console.log('Sign-out successful');
			})
			.catch(function(error) {
				console.log('An error happened');
			});
	}
	firebase.auth().onAuthStateChanged(function(resultUser) {
		if (resultUser) {
			console.log('USER LOGGED IN');
			setStatus('SignOut');
			let user = resultUser.email;
			if (user != null) setUser(`Hi, ${user.substr(0, user.indexOf('@'))}`);
			else setUser(`Hi, ${resultUser.phoneNumber}`);
		} else {
			// No user is signed in.
			console.log('USER NOT LOGGED IN');
			setUser('LOGIN');
			setStatus('');
		}
	});
	return (
		<div className="bg-emphasis">
			<Navbar bg="transparent" expand="md">
				
				{/* <Navbar.Brand className="logo-name " href="/home"> */}
						{/* <img src="/cycleworxin.png" alt="" width="30" height="24"> */}
				{/* </Navbar.Brand> */}
				
    				<a class="navbar-brand" href="/home">
      					<img src="cycleworxin.png" alt="" width="300" height="50"/>
    				</a>
  				
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end mr-5">
					<div>
						<Nav className="mr-auto">
							<Nav.Link className="mr-2 active ">
								<Link to="/home" style={{ textDecoration: 'none' }} className="text-dark">
									Home
								</Link>
							</Nav.Link>
							<Nav.Link className="mr-2 ">
								<Link to="/service" style={{ textDecoration: 'none' }} className="text-dark">
									Service Menu
								</Link>
							</Nav.Link>
							<Nav.Link className="mr-2 ">
								<Link to="/booking" style={{ textDecoration: 'none' }} className="text-dark">
									Service Booking
								</Link>
							</Nav.Link>
							<Nav.Link className="mr-2 ">
								{userName === 'Login' ? (
									<Link to="/login" style={{ textDecoration: 'none' }} className="text-dark">
										{userName}
									</Link>
								) : (
									<Link to="/user" style={{ textDecoration: 'none' }} className="text-dark">
										{userName}
									</Link>
								)}
							</Nav.Link>
						</Nav>
					</div>
				</Navbar.Collapse>
			</Navbar>
			<Container>
				<Row className="box row align-items-center">
					<Col lg="8">
						<h1 className=" text-light slogan-1 slogan-anim">
							Your Fitness <small>Mantra</small>
						</h1>
						<p className="slogan-2 text-light">
							<span> Professional Cycle Care. </span>
							<span> Your cycling journey starts here!</span>
						</p>
						<button className="btn btn-outline-light">
							<Link
								to="/booking"
								style={{ textDecoration: 'none', color: 'white' }}
								className="bg-transparent text-light"
							>
								Book Now
							</Link>
						</button>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default NavComponent;

/*
		
	<Link to="/login" style={{ textDecoration: 'none' }} className="text-dark">
									{userName}
								</Link>

*/

import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import firebase from '../infocomponents/firebase';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';
import BlankComponent from './BlankComponent';
import FillPersonalComponent from './FillPersonalComponent';
import FillAddressComponent from './FillAddressComponent';
import FillHistoryComponent from './FillHistoryComponent';

function DashboardBodyComponent() {
	const [ userName, setUser ] = useState('LOGIN');
	const [ firstName, setFName ] = useState(' ');
	const [ userId, setUID ] = useState(' ');
	const [ lastName, setLName ] = useState(' ');
	const [ email, setEmail ] = useState(' ');
	const [ phone, setPhone ] = useState(' ');
	const [ address, setAddress ] = useState(' ');
	const [ pin, setPin ] = useState(' ');
	const [ city, setCity ] = useState(' ');
	const [ state, setState ] = useState(' ');
	const [ noPersonalInfo, setPInfo ] = useState(true);
	const [ noAddressInfo, setAInfo ] = useState(true);
	const [ openUserEdit, setUserEdit ] = useState(false);
	const [ openAddEdit, setAddEdit ] = useState(false);
	const [ loginStatus, setStatus ] = useState('');

	function signOutUser() {
		firebase
			.auth()
			.signOut()
			.then(function() {
				console.log('Sign-out successful');
			})
			.catch(function(error) {
				console.log('An error happened');
			});
	}

	useEffect(
		() => {
			console.log('user logged in again');
		},
		[ userId ]
	);

	firebase.auth().onAuthStateChanged(function(resultUser) {
		if (resultUser) {
			console.log('USER LOGGED IN');
			setStatus('SignOut');
			let user = resultUser.email;
			if (user != null) setUser(`${user.substr(0, user.indexOf('@'))}`);
			else setUser(`Hi, ${resultUser.phoneNumber}`);
			setUID(resultUser.uid);
			firebase
				.firestore()
				.collection('userDetailsPersonal')
				.doc(userId)
				.get()
				.then((doc) => {
					if (doc.exists) {
						const data = doc.data();
						// object with key-value pair
						setFName(data.firstName);
						setEmail(data.emailAddress);
						setPhone(data.phone);
						setLName(data.lastName);
					} else {
						// doc.data() will be undefined in this case
						console.log('No such document!');
						setPInfo(false);
					}
				})
				.catch(function(error) {
					console.log('Error getting document:', error);
				});
			firebase
				.firestore()
				.collection('userAddress')
				.doc(userId)
				.get()
				.then((doc) => {
					if (doc.exists) {
						const data = doc.data();
						// object with key-value pair
						setAddress(data.address);
						setPin(data.pin);
						setCity(data.city);
						setState(data.state);
					} else {
						// doc.data() will be undefined in this case
						console.log('No such document!');
						setAInfo(false);
					}
				})
				.catch(function(error) {
					console.log('Error getting document:', error);
				});
		} else {
			// No user is signed in.
			console.log('USER NOT LOGGED IN');
			setUser('LOGIN');
			setStatus('');
		}
	});

	function onHandleEditPersonal() {
		setUserEdit(true);
		setAddEdit(false);
		window.scrollTo({
			top: 400,
			left: 100,
			behavior: 'smooth'
		});
	}
	function onHandleEditAddress() {
		setAddEdit(true);
		setUserEdit(false);
		window.scrollTo({
			top: 400,
			left: 100,
			behavior: 'smooth'
		});
	}

	return (
		<div>
			<Container>
				<Row>
					<Col>
						<Card className="text-center  mb-5 " border="dark" bg="transparent">
							<Card.Header className="slogan-1 display-4">Dashboard User</Card.Header>
							<Card.Body>
								<Container>
									<Row>
										<Col md={6}>
											<Card bg="transparent">
												<Card.Header className="slogan-2">
													<h4>Personal</h4>
												</Card.Header>
												{noPersonalInfo ? (
													<Card.Text className="slogan-2 mt-3 mb-3">
														No Info Added Yet
													</Card.Text>
												) : (
													<div>
														<Card.Text className="slogan-2 mt-2 mb-2">
															{firstName}
														</Card.Text>
														<Card.Text className="slogan-2 mt-2 mb-2">{lastName}</Card.Text>
														<Card.Text className="slogan-2 mt-2 mb-2">{email}</Card.Text>
														<Card.Text className="slogan-2 mt-2 mb-2">{phone}</Card.Text>
													</div>
												)}

												<Card.Footer className="text-muted">
													<button
														className="btn btn-outline-dark "
														onClick={onHandleEditPersonal}
													>
														Edit Details
													</button>
												</Card.Footer>
											</Card>
										</Col>
										<Col md={6}>
											<Card bg="transparent">
												<Card.Header className="slogan-2">
													<h4>Address</h4>
												</Card.Header>
												{noAddressInfo ? (
													<Card.Text className="slogan-2 mt-3 mb-3">
														No Info Added Yet
													</Card.Text>
												) : (
													<div>
														<Card.Text className="slogan-2 mt-2 mb-2">{address}</Card.Text>
														<Card.Text className="slogan-2 mt-2 mb-2">{city}</Card.Text>
														<Card.Text className="slogan-2 mt-2 mb-2">{pin}</Card.Text>
														<Card.Text className="slogan-2 mt-2 mb-2">{state}</Card.Text>
													</div>
												)}
												<Card.Footer className="text-muted">
													<button
														className="btn btn-outline-dark "
														onClick={onHandleEditAddress}
													>
														Edit Details
													</button>
												</Card.Footer>
											</Card>
										</Col>
									</Row>
								</Container>
							</Card.Body>
							<Card.Footer className="text-muted">
								<Card.Text>
									<Link className="text-dark slogan-1" to="/">
										Cycleworx
									</Link>
								</Card.Text>
							</Card.Footer>
						</Card>
					</Col>
				</Row>
			</Container>
			<Container>
				<Row>
					<Col>
						{openUserEdit ? <FillPersonalComponent /> : <BlankComponent />}
						{openAddEdit ? <FillAddressComponent /> : <BlankComponent />}
					</Col>
				</Row>
			</Container>
			<Container>
				<Row>
					<Col>
						<FillHistoryComponent />
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default DashboardBodyComponent;

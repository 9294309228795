import React from 'react';
import './App.css';
import FootComponet from './components/FootComponent';
import HomeContent from './components/HomeContent';
import ServiceComponent from './components/ServiceComponent';
import LoginComponent from './components/logincomponents/LoginComponent';
import RegisterComponent from './components/registercomponents/RegisterComponent';
import FaqComponent from './components/FaqComponent';
import OtpComponent from './components/otpcomponents/OtpComponent';
import UserDashboardComponent from './components/UserDashboardComponent';
import BookSlotComponent from './components/BookSlotComponent';
import { BrowserRouter as Router, Switch, Route, Link } from 'react-router-dom';

function App() {
	return (
		<div className="App">
			<Router>
				<Switch>
					<Route path="/" exact component={HomeContent} />
					<Route path="/login" exact component={LoginComponent} />
					<Route path="/register" exact component={RegisterComponent} />
					<Route path="/otp" exact component={OtpComponent} />
					<Route path="/home" exact component={HomeContent} />
					<Route path="/user" exact component={UserDashboardComponent} />
					<Route path="/service" component={ServiceComponent} />
					<Route path="/booking" component={BookSlotComponent} />
					<Route path="/faq" component={FaqComponent} />
				</Switch>
				<FootComponet />
			</Router>
		</div>
	);
}

export default App;

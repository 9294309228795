import * as firebase from 'firebase';
import 'firebase/auth';

const firebaseConfig = {
	apiKey: 'AIzaSyALwwGSmImHcHS7v875DPKmqDb_fRwFB_k',
	authDomain: 'cycleworxcare-97597.firebaseapp.com',
	databaseURL: 'https://cycleworxcare-97597.firebaseio.com',
	projectId: 'cycleworxcare-97597',
	storageBucket: 'cycleworxcare-97597.appspot.com',
	messagingSenderId: '186454264081',
	appId: '1:186454264081:web:32a731a951a7b2c57f24b0',
	measurementId: 'G-HZ656T0JNV'
};

firebase.initializeApp(firebaseConfig);

export default firebase;

import React from 'react';
import NavComponent from './NavComponent';
import PackageComponent from './servicecomponents/PackageComponent';
import PricingComponent from './servicecomponents/PricingComponent';
import PickUpHome from './infocomponents/PickUpHome';
import SericeHome from './infocomponents/ServiceHome';

function ServiceComponent() {
	window.scrollTo({
		top: 400,
		left: 100,
		behavior: 'smooth'
	});
	return (
		<div>
			<NavComponent />
			<br />
			<br />
			<PackageComponent />
			<br />
			<br />
			<PricingComponent />
			<br />
			<br />
			<PickUpHome />
			<br />
			<br />
		</div>
	);
}

export default ServiceComponent;

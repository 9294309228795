import React from 'react';

function Headline() {
	return (
		<div>
			<div className="main">
				<div className="jumbotron jumbotron-fluid bg-dark">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<p className="text-center text-light slogan-1 lead m-3">
								Making premium bicycle servicing & repair accessible and professional</p>
							<p className="text-center text-light slogan-1 lead m-3">
								Trained & Certified Technicians</p>
							<p className="text-center text-light slogan-1 lead m-3">
								High Quality Tools and Spares </p>
							<p className="text-center text-light slogan-1 lead m-3">
								Pick & Drop Service </p>
							<p className="text-center text-light slogan-1 lead m-3">
								Full stack service from cycle exchange & upgarde to insurance </p>
							</div>
					</div>
				</div>
			</div>
			<div className="container">
				<div className="row  justify-content-center">
					<h1 className="text-uppercase display-4 slogan-1">Why Us</h1>
				</div>
				<div className="row justify-content-center">
					<div className="col-5 ">
						<hr className="new4 " />
					</div>
				</div>
				<div className="row pt-5 pb-4">
					<p className="text-center m-3 lead">
						<small className="slogan-2">
							<small>
								<div className="display-4">
									A dedicated team of professionals trying to make cycling experience more{' '}
									<span className="slogan-1">Enjoyable.</span>
								</div>
							</small>
						</small>
					</p>
				</div>
			</div>
			<div className="mt-5 mb-5" />
		</div>
	);
}

export default Headline;

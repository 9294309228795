import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';
import firebase from '../infocomponents/firebase';

function BodyBookingComponent(props) {
	const [ brand, setBrand ] = useState('');
	const [ otherBrand, setOther ] = useState('');
	const [ show, setShow ] = useState(false);
	const [ userName, setUser ] = useState('');
	const [ transId, setTransId ] = useState('');

	useEffect(
		() => {
			if (brand === 'Other') {
				setShow(true);
			} else setShow(false);
		},
		[ brand, otherBrand ]
	);

	firebase.auth().onAuthStateChanged(function(resultUser) {
		if (resultUser) {
			console.log('USER LOGGED IN');
			document.getElementById('booking_form').scrollIntoView({ behavior: 'smooth' });
			setUser(resultUser.uid);
			console.log(brand);
		} else {
			// No user is signed in.
			console.log('USER NOT LOGGED IN');
		}
	});

	function handleOnSelect(event) {
		let brandName = document.getElementById('formSelectBrand').value;
		setBrand(brandName);
	}
	function handleOnEnter(event) {
		let otherBrand = document.getElementById('formOtherBrand').value;
		setOther(otherBrand);
	}

	function handleOnSubmit(event) {
		let info = {}; //create an empty object
		let date = new Date();
		info.transId = uuidv4();
		info.firstName = document.getElementById('formFirstName').value;
		info.lastName = document.getElementById('formLastName').value;
		info.emailAdd = document.getElementById('formEmail').value;
		info.slotTime = document.getElementById('formSelectSlot').value;
		info.address = document.getElementById('formAddress').value;
		info.pinNo = document.getElementById('formPIN').value;
		info.cityName = document.getElementById('formCity').value;
		info.State = document.getElementById('formState').value;
		info.phoneNo = document.getElementById('formPhone').value;
		info.serviceType = document.getElementById('formSelectServiceType').value;
		info.brandName = document.getElementById('formSelectBrand').value;
		info.cycletype = document.getElementById('formCycleType').value;
		info.discountCode = document.getElementById('formDiscCode').value;
		info.serviceDate = document.getElementById('formServiceDate').value;
		info.servicePackage = document.getElementById('formServicePackage').value;
		info.customService = document.getElementById('formCustomService').value;
		setBrand(info.brandName);
		console.log(brand);
		if (brand === '') {
			setBrand(otherBrand);
		} else {
			console.log(brand);
		}
		info.modelNo = document.getElementById('formModel').value;
		let year = date.getFullYear();
		let month = date.getMonth() + 1;
		let day = date.getDate();
		info.transaction = day.toString() + '-' + month.toString() + '-' + year.toString();
		let dateNameId =
			year.toString() +
			'/' +
			month.toString() +
			'/' +
			day.toString() +
			'|' +
			info.firstName +
			'|' +
			info.lastName;

		if (
			info.firstName !== '' &&
			info.lastName !== '' &&
			info.address !== '' &&
			info.address !== '' &&
			info.cityName !== '' &&
			info.pinNo !== '' &&
			info.emailAdd !== '' &&
			info.State !== '' &&
			info.phoneNo !== '' &&
			info.modelNo !== ''
		) {
			firebase
				.firestore()
				.collection('orders')
				.doc(dateNameId)
				.set({
					firstName: info.firstName,
					lastName: info.lastName,
					emailAddress: info.emailAdd,
					city: info.cityName,
					pin: info.pinNo,
					address: info.address,
					state: info.State,
					brand: info.brandName,
					transactionDate: info.transaction,
					otherBrand: otherBrand,
					serviceType: info.serviceType,
					model: info.modelNo,
					phone: info.phoneNo,
					slot: info.slotTime,
					cycletype: info.cycletype,
					discode: info.discountCode,
					servicedate: info.serviceDate,
					customservice: info.customService,
					servicepackage: info.servicePackage
				})
				.then(function() {
					console.log('Document successfully written!');
					const sendConfirmMail = firebase.functions().httpsCallable('sendConfirmEmail');
					if (info.otherBrand === undefined) info.otherBrand = ' ';
					sendConfirmMail({
						userName: `${info.firstName}`,
						email: `${info.emailAdd}`,
						servicedate: `${info.serviceDate}`,
						slot: `${info.slotTime}`,
						brandName: `${info.brandName} ${info.otherBrand}`,
						serviceType: `${info.serviceType}`,
						servicePackage: `${info.servicePackage}`,
						date: `${info.transaction}`,
						transactionId: `${info.transId}`,
						mobileNo: `${info.phoneNo}`,
						customservice: `${info.customService}`
					}).then((result) => {
						console.log(result);
					});
					alert(`Thanks for Request.Your reference number ${info.transId}.
			Our team will get in touch with you for the confirmation`);
				})
				.catch(function(error) {
					console.error('Error writing document: ', error);
				});

			firebase
				.firestore()
				.collection('transactions')
				.doc(userName)
				.collection('history')
				.doc(info.transId)
				.set({
					firstName: info.firstName,
					lastName: info.lastName,
					emailAddress: info.emailAdd,
					transactionDate: info.transaction,
					serviceType: info.serviceType,
					model: info.modelNo,
					phone: info.phoneNo,
					servicedate: info.serviceDate,
					slot: info.slotTime,
					id: info.transId,
					transStatus: 'pending',
					totalAmount: '0',
					cycletype: info.cycletype,
					discode: info.discountCode,
					customservice: info.customService,
					servicepackage: info.servicePackage
				})
				.then(function() {
					console.log('transaction successful!');
				})
				.catch(function(error) {
					console.error('Error writing document: ', error);
				});
		} else {
			alert('Please enter all the details to proceed with the request!');
		}
		window.scrollTo({
			top: 0,
			behavior: 'smooth'
		});
	}

	return (
		<div id="booking_form">
			<Container>
				<Row>
					<Col>
						<Card bg="transparent">
							<Card.Header>
								<h3 className="slogan-1 font-weight-bold">Fill the details</h3>
							</Card.Header>
							<Container>
								<Row className="justify-content-center">
									<Col md={8} className="my-auto">
										<br />
										<Form bg="transparent">
											<Form.Group controlId="formFirstName" required>
												<Form.Label>
													Enter Your First Name<sup>*</sup>
												</Form.Label>
												<Form.Control type="name" placeholder="First Name" />
											</Form.Group>
											<Form.Group controlId="formLastName">
												<Form.Label>Enter Your Last Name</Form.Label>
												<Form.Control type="name" placeholder="Last Name" />
											</Form.Group>
											<Form.Group controlId="formEmail">
												<Form.Label>
													Enter Your Email<sup>*</sup>
												</Form.Label>
												<Form.Control type="email" placeholder="Email" />
											</Form.Group>
											<Form.Group controlId="formPhone">
												<Form.Label>
													Enter Phone No<sup>*</sup>
												</Form.Label>
												<Form.Control type="number" placeholder="Phone No." />
												<Form.Text className="text-muted">
													We'll never share your number with anyone else.
												</Form.Text>
											</Form.Group>
											<Form.Group controlId="formServiceDate">
												<Form.Label>
													Select the Preferred Pickup Date<sup>*</sup>
												</Form.Label>
												<Form.Control type="date" placeholder="Pickup Date" />
												<Form.Text className="text-muted">
													Date entered is only for your convenience. Final pickup slot date be
													confirmed by our team after discussion
												</Form.Text>
											</Form.Group>
											<Form.Group controlId="formSelectSlot">
												<Form.Label>
													Select Your Slot<sup>*</sup>
												</Form.Label>
												<Form.Control as="select" placeholder="Available Slots">
													<option>9:00-12:00</option>
													<option>12:00-15:00</option>
													<option>15:00-18:00</option>
													<option>18:00-21:00</option>
												</Form.Control>
												<Form.Text className="text-muted">
													Slots are only for your convenience. Final pickup slot will be
													confirmed by our team after discussion
												</Form.Text>
											</Form.Group>
											<Form.Group controlId="formAddress">
												<Form.Label>Enter Your Address</Form.Label>
												<Form.Control type="text" placeholder="Address" />
											</Form.Group>
											<Form.Group controlId="formCity">
												<Form.Label>Select Your City</Form.Label>
												<Form.Control as="select" placeholder="Cities">
													<option>Chandigarh</option>
													<option>Delhi-NCR</option>
													<option>Gurugram</option>
													<option>Mohali</option>
													<option>Panchkula</option>
													<option>Zirakpur</option>
												</Form.Control>
												<Form.Text className="text-muted">
													We are currently serving Delhi-NCR & Chandigarh Region
												</Form.Text>
											</Form.Group>
											<Form.Group controlId="formState">
												<Form.Label>Select Your State</Form.Label>
												<Form.Control as="select" placeholder="State">
													<option>Delhi-NCR</option>
													<option>Haryana</option>
													<option>Punjab</option>
													<option>Chandigarh</option>
												</Form.Control>
											</Form.Group>
											<Form.Group controlId="formPIN">
												<Form.Label>Enter PIN</Form.Label>
												<Form.Control type="number" placeholder="PIN Code" />
											</Form.Group>
											
											<Form.Group controlId="formSelectServiceType">
												<Form.Label>Select Service Type</Form.Label>
												<Form.Control as="select" placeholder="Service Type">
													<option>Go To Service Centre</option>
													<option>Pick & Drop</option>
												</Form.Control>
											</Form.Group>
											<Form.Group controlId="formServicePackage">
												<Form.Label>Select Service Packages</Form.Label>
												<Form.Control as="select" placeholder="Service Package">
													<option>Yearly-Advanced-2299 INR</option>
													<option>Yearly-Premium-1799 INR</option>
													<option>Half-Yearly-Advanced-1999 INR </option>
													<option>Half-Yearly-Premium-1499 INR</option>
													<option>Stanard Service-699 INR</option>
													<option>Premium Service-1199 INR</option>
													<option>Advanced Service-1999 INR</option>
													<option>Custom Repair & Service Instructions</option>
												</Form.Control>
												<Form.Text className="text-muted">
													You can refer to service section for difference in different service
													packages
												</Form.Text>
											</Form.Group>
											<Form.Group controlId="formCustomService">
												<Form.Label>
													Please Enter Service & Repair Instructions e.g. crank making sound
													etc.
												</Form.Label>
												<Form.Control type="text" placeholder="Service Instructions" />
											</Form.Group>

											<Form.Group controlId="formSelectBrand">
												<Form.Label>Brand</Form.Label>
												<Form.Control
													as="select"
													placeholder="Brand Name"
													onChange={handleOnSelect}
												>
													<option>Trek</option>
													<option>Giant</option>
													<option>Firefox</option>
													<option>Hero</option>
													<option>Lapierre</option>
													<option>Ducati</option>
													<option>Kross</option>
													<option>Lapierre</option>
													<option>Bianchi</option>
													<option>Cannondale</option>
													<option>Raleigh</option>
													<option>Btwin</option>
													<option>Other</option>
												</Form.Control>
											</Form.Group>
											{show ? (
												<div id="other_brand">
													<Form.Group controlId="formOtherBrand">
														<Form.Control
															type="text"
															placeholder="Brand Name"
															onChange={handleOnEnter}
														/>
													</Form.Group>
												</div>
											) : null}
											<Form.Group controlId="formCycleType">
												<Form.Label>Cycle Type</Form.Label>
												<Form.Control as="select" placeholder="Service Type">
													<option>Road Bike</option>
													<option>Hybrid</option>
													<option>MTB</option>
													<option>Folding cycle</option>
													<option>Kids Cycle</option>
												</Form.Control>
											</Form.Group>

											<Form.Group controlId="formModel">
												<Form.Label>Cycle Model</Form.Label>
												<Form.Control type="text" placeholder="Model No" />
											</Form.Group>
											<Form.Group controlId="formDiscCode">
												<Form.Label>Referral/Discount Code</Form.Label>
												<Form.Control type="text" placeholder="Code" />
											</Form.Group>
											<br />
											<Button variant="primary" onClick={handleOnSubmit} id="submitButton">
												Submit
											</Button>
										</Form>

										<br />
									</Col>
								</Row>
							</Container>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default BodyBookingComponent;

/*


											<Form.Group controlId="formState">
												<Form.Label>Enter Your State</Form.Label>
												<Form.Control type="text" placeholder="State" />
											</Form.Group>


<Form.Group controlId="formDistrict">
												<Form.Label>Enter Your District</Form.Label>
												<Form.Control type="text" placeholder="District" />
											</Form.Group>
*/

import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import m1 from './images/Vikram_Tools.jpeg';
import m2 from './images/tool1.jpg';
import m3 from './images/tool2.jpg';
import m4 from './images/tool3.jpg';
import m5 from './images/tool4.jpg';
import m6 from './images/tool5.jpg';
function TrainedProfessionComponent() {
	return (
		<div>
			<hr class="descript-user " />

			<br />
			<br />
			<Container>
				<Row className="justify-content-center">
					<Col lg={6} md={12}>
					<Carousel indicators={false} fade={true} interval={4000}>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={m1} alt="image-pic" class="tool img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={m3} alt="image-pic" class="tool img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={m4} alt="image-pic" class="tool img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={m5} alt="image-pic" class="tool img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={m2} alt="image-pic" class="tool img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
								</Carousel.Item>
							</Carousel>
						
						</Col>
					<Col lg={6} md={12}>
						<br className="d-none d-lg-block " />
						<br className="d-none d-lg-block " />
						<br />
						<div className="text-center mt-5  slogan-1 display-4">Trained Professionals</div>
					</Col>
				</Row>
			</Container>
			<Container>
				<Row>
					<Col>
						<br />
						<br />
						<div className="text-center mt-3 slogan-2 font-weight-bold">
							All our technician are certified by major cycling brands like Trek,Shimano etc. & has over
							decade of experience in handling all type of cycle make & models
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default TrainedProfessionComponent;

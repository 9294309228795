import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import firebase from '../infocomponents/firebase';

function FillHistoryComponent() {
	const [ noHistory, setHistory ] = useState(true);
	const [ userId, setUID ] = useState(' ');
	const historyData = document.querySelector('#historyList');

	firebase.auth().onAuthStateChanged(function(resultUser) {
		if (resultUser) {
		setUID(resultUser.uid);
		} else {
			// No user is signed in.
			console.log('USER NOT LOGGED IN');
		}

	});

	useEffect(
		() => {
			firebase
				.firestore()
				.collection('transactions')
				.doc(userId)
				.collection('history')
				.get()
				.then((querySnapshot) => {
					querySnapshot.docs.forEach((doc) => {
						renderHistory(doc);
						console.log(doc.data());
					});
				});
		},
		[ userId ]
	);

	function renderHistory(doc) {
		let li = document.createElement('div');
		let gap = document.createElement('br');
		let hr_1 = document.createElement('hr');
		let hr_2 = document.createElement('hr');
		let hr_3 = document.createElement('hr');
		let hr_4 = document.createElement('hr');
		let container = document.createElement('div');
		let transactionId = document.createElement('div');
		let curStatus = document.createElement('div');
		let date = document.createElement('div');
		let totalAmount = document.createElement('div');
		let servicePack = document.createElement('div');

		li.setAttribute('data-id', doc.id);
		li.className = 'card-history';
		container.className = 'container-history';
		transactionId.className = 'slogan-2';
		curStatus.className = 'slogan-2';
		date.className = 'slogan-2';
		totalAmount.className = 'slogan-2';
		servicePack.className = 'slogan-2';
		hr_1.className = 'descript-history ml-2 pr-3';
		hr_2.className = 'descript-history ml-2 pr-3';
		hr_3.className = 'descript-history ml-2 pr-3';
		hr_4.className = 'descript-history ml-2 pr-3';
		transactionId.textContent = 'Transaction ID: ' + doc.data().id;
		curStatus.textContent = 'Status: ' + doc.data().transStatus;
		date.textContent = 'Dated: ' + doc.data().transactionDate;
		totalAmount.textContent = 'Total Amount: ' + doc.data().totalAmount;
		servicePack.textContent = 'Service Package: ' + doc.data().servicepackage;
		li.appendChild(container);
		container.appendChild(transactionId);
		container.appendChild(hr_1);
		container.appendChild(curStatus);
		container.appendChild(hr_2);
		container.appendChild(date);
		container.appendChild(hr_3);
		container.appendChild(totalAmount);
		container.appendChild(hr_4);
		container.appendChild(servicePack);
		historyData.appendChild(li);
		historyData.appendChild(gap);
	}

	return (
		<div>
			<Card bg="transparent">
				<Card.Header className="slogan-1">
					<h4>History</h4>
				</Card.Header>
				<Container>
					<Row>
						<Col>
							<div id="historyList" className="mt-5 mb-4" />
						</Col>
					</Row>
				</Container>
			</Card>
		</div>
	);
}

export default FillHistoryComponent;

import React, { useState } from 'react';

function BlankComponent() {
	return (
		<div>
			<div />
		</div>
	);
}

export default BlankComponent;

import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

function LogNavComponent() {
	return (
		<div>
			<Navbar bg="light" expand="md">
				<Navbar.Brand className="logo-name ">
					<a class="navbar-brand" href="/home">
                        <img src="cycleworxin.png" alt="" width="300" height="50"/>
                    </a>
				
				</Navbar.Brand>
					

				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end mr-5">
					<div>
						<Nav className="mr-auto">
							<Nav.Link className="mr-2 active ">
								<Link to="/home" style={{ textDecoration: 'none' }} className="text-dark">
									Home
								</Link>
							</Nav.Link>
							<Nav.Link className="mr-2 ">
								<Link to="/service" style={{ textDecoration: 'none' }} className="text-dark">
									Service Menu
								</Link>
							</Nav.Link>
							<Nav.Link className="mr-2 ">
								<Link to="/booking" style={{ textDecoration: 'none' }} className="text-dark">
									Service Booking
								</Link>
							</Nav.Link>
							<Nav.Link className="mr-2 ">
								<Link to="/login" style={{ textDecoration: 'none' }} className="text-dark">
									Login
								</Link>
							</Nav.Link>
							<Nav.Link className="mr-2 ">
								<Link to="/faq" style={{ textDecoration: 'none' }} className="text-dark">
									FAQ
								</Link>
							</Nav.Link>
						</Nav>
					</div>
				</Navbar.Collapse>
			</Navbar>
		</div>
	);
}

export default LogNavComponent;

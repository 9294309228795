import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import LogNavComponent from '../LogNavComponent';
import firebase from '../infocomponents/firebase';
import { Link } from 'react-router-dom';

function RegisterComponent(props) {
	const [ logged, setLogin ] = useState(false);
	function registerUser() {
		let userName = document.getElementById('formUserName').value;
		let userPhone = document.getElementById('formPhone').value;
		let email = document.getElementById('formEmail').value;
		let password = document.getElementById('formPassword').value;
		let discCode = document.getElementById('formDiscCode').value;
		if (userName === '' && userPhone === '' && email === '' && password === '') {
			alert('Enter All Details To Register');
			setLogin(!logged);
		} else {
			firebase
				.auth()
				.createUserWithEmailAndPassword(email, password)
				.then(function(result) {
					props.history.push('/home');
					let uuid = result.user.uid;
					console.log(result.user.uid);

					firebase
						.firestore()
						.collection('users')
						.doc(uuid)
						.set({
							userName: userName,
							email: email,
							userPhone: userPhone,
							discode: discCode
						})
						.then(function() {
							console.log('Document successfully written!');
							alert('User Registered');
						})
						.catch(function(error) {
							console.error('Error writing document: ', error);
						});
				})
				.catch(function(error) {
					alert('User Already Registered ');
					console.log('An error happened');
				});
		}
	}
	function checkUser() {}

	return (
		<div>
			<LogNavComponent />
			<Container className="Login-component" fluid>
				<Container className="pt-4 ">
					<Container className="pt-1 ">
						<Container className="pt-2 ">
							<Row className="justify-content-center">
								<Col md={8} className="my-auto">
									<Card>
										<Card.Header>
											<h3 className="slogan-1 font-weight-bold">Register</h3>
										</Card.Header>
										<Card.Body>
											<Form>
												<Form.Group controlId="formUserName">
													<Form.Label>Enter your username</Form.Label>
													<Form.Control type="name" placeholder="Username" />
												</Form.Group>
												<Form.Group controlId="formPhone">
													<Form.Label>Enter your phone no.</Form.Label>
													<Form.Control type="number" placeholder="Phone" />
												</Form.Group>
												<Form.Group controlId="formEmail">
													<Form.Label>Enter your email address</Form.Label>
													<Form.Control type="email" placeholder="Email" />
												</Form.Group>
												<Form.Group controlId="formPassword">
													<Form.Label>Password</Form.Label>
													<Form.Control type="password" placeholder="Password" />
												</Form.Group>
												<Form.Group controlId="formDiscCode">
													<Form.Label>Referral / Discount Code</Form.Label>
													<Form.Control type="password" placeholder="code" />
												</Form.Group>
												<Button
													variant="primary"
													id="submitButton"
													className="mt-3"
													onClick={registerUser}
												>
													Register
												</Button>
											</Form>
										</Card.Body>
									</Card>
									<Container className="mt-3 ">
										<Row className="justify-content-center">
											<Col md={9} className="my-auto pl-5">
												<Link to="/login" style={{ textDecoration: 'none', color: 'white' }}>
													<h4 className="slogan-1 text-light text-center">
														{' '}
														Already Signed Up ? Login{' '}
													</h4>
												</Link>
											</Col>
										</Row>
									</Container>
								</Col>
							</Row>
						</Container>
					</Container>
				</Container>
			</Container>
		</div>
	);
}

export default RegisterComponent;

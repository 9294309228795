import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function CycleAssistComponent() {
	return (
		<div>
			<br />
			<br />
			<Container>
				<Row>
					<Col md={6} sm={12}>
						<br />
						<br />
						<br />
						<hr className="new4 " />
						<br />
						<br />
						<div className="text-center  slogan-1 display-4">Cycle Assist</div>
						<br />
						<br />
						<hr className="new4 " />
					</Col>
					<Col md={6} sm={12}>
						<iframe
							className="youtube-vid"
							src="https://www.youtube.com/embed/oE3e-jKLKMc?autoplay=0&mute=1&player=html5"
							frameborder="0"
						/>
					</Col>
				</Row>
			</Container>
			<Container>
				<Row>
					<Col>
						<div className="text-center mt-3 slogan-2 font-weight-bold">
							We understand the cycle and cyclists need and provide free roadside assistance for our
							enrolled members & clubs across Delhi-NCR and Chandigarh Tri-city
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default CycleAssistComponent;

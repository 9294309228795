import React from 'react';
import Headline from './homecomponents/Headline';
import NavComponent from './NavComponent';
import Description from './homecomponents/Description';
import Testimonial from './homecomponents/Testimonial';

function HomeContent() {
	window.scrollTo({
		top: 0,
		left: 100,
		behavior: 'smooth'
	});
	return (
		<div>
			<NavComponent />
			<Headline />
			<Description />
			<br />
			<br />
			<Testimonial />
			<br />
			<br />
		</div>
	);
}

export default HomeContent;

import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';

function PackageComponent() {
	return (
		<div>
			<Container>
				<Row className="justify-content-center">
					<Col sm={12} md={10} className="my-auto">
						<h1 className="slogan-1 text-center">We understand your cyle's need</h1>
					</Col>
					<br />
					<Col md={10} className="my-auto">
						<hr className="new4" />
					</Col>
				</Row>
			</Container>
			<Container>
				<Row>
					<Col>
						<Card className="text-center mt-5 mb-5 " border="dark" bg="transparent">
							<Card.Header className="slogan-1 display-4">SERVICE PACKAGES WE PROVIDE</Card.Header>
							<Card.Body>
								<Container>
									<Row>
										<Col md={4}>
											<Card bg="transparent">
												<Card.Header className="slogan-2">
													<h4>Standard</h4>
												</Card.Header>
												<Card.Text>
													<div>
													<h5 className="slogan-1">Gear Tune up</h5>
													<h5 className="slogan-1">Hubs Checkup</h5>
													<h5 className="slogan-1">Headset Alignment</h5>
													<h5 className="slogan-1">Bottom Bracket Checkup</h5>
													<h5 className="slogan-1">Brakes Inspection & Adjustment</h5>
													<h5 className="slogan-1">Chain Inspection & Lubrication</h5>
													<h5 className="slogan-1">Cables Inspection & Lubrication</h5>
													<h5 className="slogan-1">Cycle Cleaning</h5>
													<h5 className="slogan-1">Tyres Inspection & Inflation to correct pressure</h5>
													<h5 className="slogan-1">Checking and Tightening all screws and bolts</h5>
													</div>
												</Card.Text>
											</Card>
										</Col>
										<Col md={4}>
											<Card bg="transparent">
												<Card.Header className="slogan-2">
													<h4>Premium</h4>
												</Card.Header>
												<Card.Text>
													<br />
														<h3 className="logo-name text-center">Standard Service</h3>
													<br />
													<br />
														<h3 className="logo-name text-center">+</h3>
													<br />
													<div>
													<h5 className="slogan-1">Hubs regreasing</h5>
													<h5 className="slogan-1">Wheel Truing ( Not wheel bend )</h5>
													<h5 className="slogan-1">Dismantling of Hubs, Center Bracket and Headset</h5>
												</div>
												</Card.Text>
											</Card>
										</Col>
										<Col md={4}>
											<Card bg="transparent">
												<Card.Header className="slogan-2">
													<h4>Advanced</h4>
												</Card.Header>
												<Card.Text>
												<br />
														<h3 className="logo-name text-center">Premium Service</h3>
													<br />
													<br />
														<h3 className="logo-name text-center">+</h3>
													<br />
													<div>
														<h5 className="slogan-1">Bike Spa</h5>
														<h5 className="slogan-1">De-grease/Re-grease and re-install Hubs/BB/Headset</h5>
														<h5 className="slogan-1">Brake bleeding</h5>
														<h5 className="slogan-1">Lubricate gear cable (inner/outer)</h5>
														<h5 className="slogan-1">Check wheel true/tension/dish on wheel stand</h5>
														<h5 className="slogan-1">Lubricate drive</h5>
														<h5 className="slogan-1">Gear and brake adjustment</h5>
														<h5 className="slogan-1">Check torque on all bolts to manufacturer’s specifications</h5>
														<h5 className="slogan-1">Safety check and report</h5>
														<h5 className="slogan-1">Race Tune</h5>
													</div>
												</Card.Text>
											</Card>
										</Col>
									</Row>
								</Container>
							</Card.Body>
							<Card.Footer className="text-muted">
								<button className="btn btn-outline-dark ">
									<Link className="text-light" to="/booking">
										Book a slot
									</Link>
								</button>
							</Card.Footer>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default PackageComponent;

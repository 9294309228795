import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import firebase from '../infocomponents/firebase';

function FillAddressComponent() {
	const [ userId, setUID ] = useState(' ');
	const [ scrollTop, setScroll ] = useState(false);

	useEffect(
		() => {
			window.scrollTo({
				top: 0,
				left: 100,
				behavior: 'smooth'
			});
		},
		[ scrollTop ]
	);

	firebase.auth().onAuthStateChanged(function(resultUser) {
		if (resultUser) {
			console.log('USER LOGGED IN');
			setUID(resultUser.uid);
		} else {
			// No user is signed in.
			console.log('USER NOT LOGGED IN');
		}
	}).catch(function(error) {
		console.log("Got an error: ",error);
		});

	function handleOnSubmit() {
		let info = {}; //create an empty object
		info.address = document.getElementById('formAddress').value;
		info.pinNo = document.getElementById('formPIN').value;
		info.cityName = document.getElementById('formCity').value;
		info.State = document.getElementById('formState').value;

		firebase
			.firestore()
			.collection('userAddress')
			.doc(userId)
			.set({
				city: info.cityName,
				pin: info.pinNo,
				address: info.address,
				state: info.State
			})
			.then(function() {
				console.log('Document successfully written!');
				setScroll(true);
			})
			.catch(function(error) {
				console.error('Error writing document: ', error);
			});
	}

	return (
		<div>
			<Container>
				<Row>
					<Col>
						<Card className="text-center  mb-5 " border="dark" bg="transparent">
							<Card.Header className="slogan-1">
								<h4>Edit Address</h4>
							</Card.Header>
							<Container>
								<Row>
									<Col>
										<Form bg="transparent">
											<Container className="mt-5">
												<Row>
													<Col md={6}>
														<Form.Group controlId="formAddress">
															<Form.Control type="text" placeholder="Address" />
														</Form.Group>
													</Col>
													<Col md={6}>
														<Form.Group controlId="formCity">
															<Form.Control as="select" placeholder="Cities">
																<option>Chandigarh</option>
																<option>Delhi-NCR</option>
																<option>Gurugram</option>
																<option>Mohali</option>
																<option>Panchkula</option>
																<option>Zirakpur</option>
															</Form.Control>
														</Form.Group>
													</Col>
												</Row>
											</Container>
											<Container className="mt-3 mb-4">
												<Row>
													<Col md={6}>
														<Form.Group controlId="formPIN">
															<Form.Control type="number" placeholder="PIN Code" />
														</Form.Group>
													</Col>
													<Col md={6}>
														<Form.Group controlId="formState">
															<Form.Control as="select" placeholder="State">
																<option>Delhi-NCR</option>
																<option>Gurugram</option>
																<option>Punjab</option>
															</Form.Control>
														</Form.Group>
													</Col>
												</Row>
											</Container>
										</Form>
									</Col>
								</Row>
							</Container>
							<Card.Footer>
								<button className="btn btn-outline-dark " onClick={handleOnSubmit}>
									<Link to="/user" style={{ textDecoration: 'none', color: 'black' }}>
										Submit
									</Link>
								</button>
							</Card.Footer>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default FillAddressComponent;

import React from 'react';
import NavComponent from './NavComponent';
import FaqHeadComponent from './faqcomponents/FaqHeadComponent';
import FaqBodyComponent from './faqcomponents/FaqBodyComponent';

function FaqComponent() {
	window.scrollTo({
		top: 300,
		left: 100,
		behavior: 'smooth'
	});
	return (
		<div>
			<NavComponent />
			<br />
			<br />
			<FaqHeadComponent />
			<br />
			<br />
			<FaqBodyComponent />
			<br />
			<br />
		</div>
	);
}

export default FaqComponent;

import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import CycleCareComponent from '../homedescriptioncomponents/CycleCareComponent';
import TrainedProfessionComponent from '../homedescriptioncomponents/TrainedProfessionComponent';
import ExchangeUpgradeComponent from '../homedescriptioncomponents/ExchangeUpgradeComponent';
import CycleRentalComonent from '../homedescriptioncomponents/CycleRentalComonent';
import CycleAssistComponent from '../homedescriptioncomponents/CycleAssistComponent';
import m1 from './images/FullStackCycling.png';
import d1 from './images/d1.jpeg';
import d2 from './images/d2.jpeg';
import d3 from './images/d3.jpeg';
import d4 from './images/d4.jpeg';
import d5 from './images/d5.jpeg';
import c1 from './images/c1.jpeg';
import c2 from './images/c2.jpeg';
import c3 from './images/c3.jpeg';
import c4 from './images/c4.jpeg';
import c5 from './images/c5.jpeg';
import g1 from './images/g1.jpg';
import g2 from './images/g2.jpg';
import g3 from './images/g3.jpg';
import g4 from './images/g4.jpg';
import g5 from './images/g5.jpg';
import g6 from './images/g6.jpg';
import g7 from './images/g7.jpg';
import g8 from './images/g8.jpg';
import g9 from './images/g9.jpg';

function Description() {
	return (
		<div>
			<section id="Description">
				<CycleCareComponent />
				<br />
				<br />
				<TrainedProfessionComponent />
				<br />
				<br />
				<br />
				<Container>
					<Row>
						
						<Col>
							<div className="text-center slogan-1 display-4">Full Stack Cycling</div>
						</Col>
						
						<Col lg={6} md={12}>
								<img src={m1}
									className="fullstackcycling img-size "/>
							</Col>
					</Row>
					<br />
					<br />
					<br />
					<Row>
						<Col>
							<div className="slogan-2 font-weight-bold text-center">
								We have one of the widest varierty of Cycles, Components ,Spares & Accessories in India {' '}
								<span>
									<Link to="/home" style={{ textDecoration: 'none' }} className="text-light">
										here.
									</Link>
								</span>
							</div>
						</Col>
					</Row>
				</Container>
				<br />
				<br />
			
				<Container>
					<Row>
						<Col>
							<hr className="new4 " />
						</Col>
					</Row>
				</Container>
					<Container>
					<Row className="justify-content-center">
					
					<Col lg={6} md={12}>
							<Carousel indicators={false} fade={true} interval={4000}>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={d1} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Delhi Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={d2} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Delhi Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={d3} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Delhi Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={d5} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Delhi Store
												</p>
											</div>
										</div>
									</div>
									</Carousel.Item>
									<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={c1} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Chandigarh Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={c2} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Chandigarh Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={c3} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Chandigarh Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={c4} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Chandigarh Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={c5} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Chandigarh Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={g1} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Gurugram Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={g2} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Gurugram Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={g3} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Gurugram Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={g4} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Gurugram Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={g5} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Gurugram Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={g6} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Gurugram Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={g7} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Gurugram Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={g8} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Gurugram Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
								<Carousel.Item>
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-xs-4 my-auto">
												<img src={g9} alt="image-pic" class="store img-size" />
											</div>
										</div>
									</div>
									<br />
									<br />
									<div class="container">
										<div class="row justify-content-center">
											<div class="col-9">
												<p class="text-center">
													Gurugram Store
												</p>
											</div>
										</div>
									</div>
									
								</Carousel.Item>
							</Carousel>
							</Col>
							<Col lg={6} md={12}>
						<br className="d-none d-lg-block " />
						<br className="d-none d-lg-block " />
						<br />
						<div className="text-center mt-5  slogan-1 display-4">Our Stores</div>
						</Col>


						</Row>
					</Container>
					<Container>
					<Row>
						<Col>
							<div className="slogan-2 font-weight-bold text-center">
								For us our stores are not just a storefront but a fully hygenic & Sanitized experience center fully equipped with amentities such as Dedicated Air Conditioned Waiting Area, Complementry Coffee, Purified Cold & Warm Water, Dedicated Workshop Area etc.{' '}
							</div>
						</Col>
					</Row>
				</Container>
				<Container>
					<Row>
						<Col>
							<hr className="new4 " />
						</Col>
					</Row>
				</Container>
				<br />
				<br />
				<br />
				<ExchangeUpgradeComponent />
				<br />
				<br />
				<CycleRentalComonent />
				<br />
				<br />
				<CycleAssistComponent />
			</section>
		</div>
	);
}

export default Description;

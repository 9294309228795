import React from 'react';
import NavComponent from './NavComponent';
import HeadlineBookingComponent from './bookingcomponents/HeadlineBookingComponent';
import firebase from './infocomponents/firebase';
import BodyBookingComponent from './bookingcomponents/BodyBookingComponent';

function BookSlotComponent(props) {
	firebase.auth().onAuthStateChanged(function(resultUser) {
		var firebaseUser = firebase.auth().currentUser;
		if (firebaseUser) {
			console.log('USER LOGGED IN');
		} else {
			// No user is signed in.
			console.log('USER NOT LOGGED IN');
			props.history.push('/login');
		}
	});
	return (
		<div>
			<NavComponent />
			<HeadlineBookingComponent />
			<br />
			<BodyBookingComponent />
			<br />
			<br />
		</div>
	);
}

export default BookSlotComponent;

import React, { useState, useEffect } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import { Link } from 'react-router-dom';
import firebase from '../infocomponents/firebase';

function FillPersonalComponent() {
	const [ userId, setUID ] = useState(' ');
	const [ scrollTop, setScroll ] = useState(false);

	useEffect(
		() => {
			window.scrollTo({
				top: 0,
				left: 100,
				behavior: 'smooth'
			});
		},
		[ scrollTop ]
	);

	firebase.auth().onAuthStateChanged(function(resultUser) {
		if (resultUser) {
			console.log('USER LOGGED IN');
			setUID(resultUser.uid);
		}
	});

	function handleOnSubmit() {
		let info = {}; //create an empty object
		info.firstName = document.getElementById('formFirstName').value;
		info.lastName = document.getElementById('formLastName').value;
		info.emailAdd = document.getElementById('formEmail').value;
		info.phoneNo = document.getElementById('formPhone').value;
		firebase
			.firestore()
			.collection('userDetailsPersonal')
			.doc(userId)
			.set({
				firstName: info.firstName,
				lastName: info.lastName,
				emailAddress: info.emailAdd,
				phone: info.phoneNo
			})
			.then(function() {
				console.log('Document successfully written!');
				setScroll(true);
			})
			.catch(function(error) {
				console.error('Error writing document: ', error);
			});
	}

	return (
		<div>
			<Container>
				<Row>
					<Col>
						<Card className="text-center  mb-5 " border="dark" bg="transparent">
							<Card.Header className="slogan-1">
								<h4>Edit Personal Details</h4>
							</Card.Header>
							<Container>
								<Row>
									<Col>
										<Form bg="transparent">
											<Container className="mt-5">
												<Row>
													<Col md={6}>
														<Form.Group controlId="formFirstName" required>
															<Form.Control type="name" placeholder="First Name" />
														</Form.Group>
													</Col>
													<Col md={6}>
														<Form.Group controlId="formLastName" required>
															<Form.Control type="name" placeholder="Last Name" />
														</Form.Group>
													</Col>
												</Row>
											</Container>
											<Container className="mt-3 mb-4">
												<Row>
													<Col md={6}>
														<Form.Group controlId="formEmail">
															<Form.Control type="name" placeholder="Email" />
														</Form.Group>
													</Col>
													<Col md={6}>
														<Form.Group controlId="formPhone">
															<Form.Control type="number" placeholder="Phone No." />
														</Form.Group>
													</Col>
												</Row>
											</Container>
										</Form>
									</Col>
								</Row>
							</Container>
							<Card.Footer>
								<button className="btn btn-outline-dark " onClick={handleOnSubmit}>
									<Link to="/user" style={{ textDecoration: 'none', color: 'black' }}>
										Submit
									</Link>
								</button>
							</Card.Footer>
						</Card>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default FillPersonalComponent;

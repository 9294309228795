import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ExchangeUpgradeComponent() {
	return (
		<div>
			<br />
			<br />
			<Container>
				<Row>
					<Col lg={6} md={12}>
						<br />
						<br />
						<br />
						<hr className="new4 " />
						<br />
						<br />
						<div className="text-center  slogan-1 display-4">Exchange & Upgrade</div>
						<br />
						<br />
						<hr className="new4 " />
					</Col>
					<Col lg={6} md={12}>
						<iframe
							className="youtube-vid"
							src="https://www.youtube.com/embed/oE3e-jKLKMc?autoplay=0&mute=1&player=html5"
							frameborder="0"
						/>
					</Col>
				</Row>
			</Container>
			<Container>
				<Row>
					<Col>
						<div className="text-center mt-3 slogan-2 font-weight-bold">
							Itching to move to road bike but still stuck with your old MTB. We can help you upgrade using segment's best exchange and assured buyback program
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default ExchangeUpgradeComponent;

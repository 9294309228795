import React from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import LogNavComponent from '../LogNavComponent';
import firebase from '../infocomponents/firebase';
import { Link } from 'react-router-dom';

function LoginComponent(props) {
	function submitEmail() {
		let email = document.getElementById('formEmail').value;
		let password = document.getElementById('formPassword').value;
		if (email !== '' && password !== '') {
			firebase
				.auth()
				.signOut()
				.then(function() {
					console.log('Sign-out successful');
				})
				.catch(function(error) {
					console.log('An error happened');
				});
			firebase
				.auth()
				.signInWithEmailAndPassword(email, password)
				.then(function(confirmationResult) {
					console.log(confirmationResult);
					window.confirmationResult = confirmationResult;
					console.log(window);
				})
				.catch(function(error) {
					console.log(error);
				});
		} else {
			alert('Enter credentials (mandatory)');
		}
	}
	firebase.auth().onAuthStateChanged(function(user) {
		if (user) {
			console.log('USER LOGGED IN');

			props.history.push('/booking');
		} else {
			// No user is signed in.
			document.getElementById('login_component').scrollIntoView({ behavior: 'smooth' });
			console.log('USER NOT LOGGED IN');
		}
	});

	return (
		<div id="login_component">
			<LogNavComponent />
			<Container className="Login-component" fluid>
				<Container className="pt-5 ">
					<Container className="pt-5 ">
						<Container className="pt-5 ">
							<Row className="justify-content-center">
								<Col md={8} className="my-auto">
									<Card>
										<Card.Header>
											<h3 className="slogan-1 font-weight-bold">Login</h3>
										</Card.Header>
										<Card.Body>
											<Form>
												<Form.Group controlId="formEmail">
													<Form.Label>Enter your email address</Form.Label>
													<Form.Control type="email" placeholder="Email" />
												</Form.Group>
												<Form.Group controlId="formPassword">
													<Form.Label>Password</Form.Label>
													<Form.Control type="password" placeholder="Password" />
													<Link to="/otp" style={{ textDecoration: 'none', color: 'white' }}>
														<Form.Text className="text-muted pt-2">
														<h6>Sign In with OTP</h6>
														</Form.Text>
													</Link>
												</Form.Group>
												<Button
													variant="primary"
													id="submitButton"
													className="mt-3"
													onClick={submitEmail}
												>
													Login
												</Button>
											</Form>
										</Card.Body>
									</Card>
									<Container className="mt-5 ">
										<Row className="justify-content-center">
											<Col md={9} className="my-auto ">
												<Link to="/register" style={{ textDecoration: 'none', color: 'white' }}>
													<h4 className="slogan-1 text-light text-center">
														{' '}
														New to cycleworx ? Register{' '}
													</h4>
												</Link>
											</Col>
										</Row>
									</Container>
								</Col>
							</Row>
						</Container>
					</Container>
				</Container>
			</Container>
		</div>
	);
}

export default LoginComponent;

import React, { useState } from 'react';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import LogNavComponent from '../LogNavComponent';
import firebase from '../infocomponents/firebase';
import { Link } from 'react-router-dom';

function OtpComponent(props) {
	const [ loggedIn, setLogin ] = useState(false);

	setTimeout(function() {
		window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
			callback: function(response) {
				submitPhoneNumberAuth();
			}
		});
	}, 2000);

	// This function runs when the 'sign-in-button' is clicked
	// Takes the value from the 'phoneNumber' input and sends SMS to that phone number
	function submitPhoneNumberAuth() {
		var no = document.getElementById('formPhone').value;
		var phoneNumber = '+91' + no.toString();
		console.log(phoneNumber);
		var appVerifier = window.recaptchaVerifier;
		document.getElementById('verify-text').textContent = 'Verify reCAPTCHA to send code';
		firebase
			.auth()
			.signOut()
			.then(function() {
				console.log('Sign-out successful');
			})
			.catch(function(error) {
				console.log('An error happened');
			});
		firebase
			.auth()
			.signInWithPhoneNumber(phoneNumber, appVerifier)
			.then(function(confirmationResult) {
				console.log(confirmationResult);
				window.confirmationResult = confirmationResult;
				console.log(window);
			})
			.catch(function(error) {
				console.log(error);
			});
	}

	// This function runs when the 'confirm-code' button is clicked
	// Takes the value from the 'code' input and submits the code to verify the phone number
	// Return a user object if the authentication was successful, and auth is complete
	function submitPhoneNumberAuthCode() {
		var code = document.getElementById('formCode').value;
		window.confirmationResult
			.confirm(code)
			.then(function(result) {
				var user = result.user;
				console.log(user);
			})
			.catch(function(error) {
				console.log(error);
			});
	}
	firebase.auth().onAuthStateChanged(function(user) {
		if (user) {
			console.log('USER LOGGED IN');
			setLogin(!loggedIn);
			props.history.push('/booking');
		} else {
			// No user is signed in.
			console.log('USER NOT LOGGED IN');
			setLogin(loggedIn);
		}
	});

	return (
		<div>
			<LogNavComponent />
			<Container className="Login-component" fluid>
				<Container className="pt-5 ">
					<Container className="pt-4 ">
						<Row className="justify-content-center">
							<Col md={7} lg={6} className="my-auto">
								<Card>
									<Card.Header>
										<h3 className="slogan-1 font-weight-bold">Otp Login</h3>
									</Card.Header>
									<Card.Body>
										<Form>
											<Form.Group controlId="formPhone">
												<Form.Label>Phone Number</Form.Label>
												<Form.Control type="number" placeholder="Phone" />
											</Form.Group>
											<Form.Group controlId="formCode">
												<Form.Label>Verification Code</Form.Label>
												<Form.Control type="password" placeholder="Code" />
												<Link to="/login" style={{ textDecoration: 'none', color: 'white' }}>
													<Form.Text className="text-muted pt-2">
														Sign In with Email
													</Form.Text>
												</Link>
											</Form.Group>
											<small>
												<div id="verify-text" />
											</small>
											<div id="recaptcha-container" />
											<Button
												variant="primary"
												id="signButton"
												className="mt-3"
												onClick={submitPhoneNumberAuth}
											>
												Send Code
											</Button>
											<span>
												<Button
													variant="primary"
													id="confirmButton"
													className="mt-3 ml-4"
													onClick={submitPhoneNumberAuthCode}
												>
													Sign In
												</Button>
											</span>
										</Form>
									</Card.Body>
								</Card>
								<Container className="mt-3 ">
									<Row className="justify-content-center">
										<Col md={10} className="my-auto ">
											<Link to="/register" style={{ textDecoration: 'none', color: 'white' }}>
												<h4 className="slogan-1 text-light text-center">
													{' '}
													New to cycleworx ? Register{' '}
												</h4>
											</Link>
										</Col>
									</Row>
								</Container>
							</Col>
						</Row>
					</Container>
				</Container>
			</Container>
		</div>
	);
}

export default OtpComponent;

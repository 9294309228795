import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function CycleCareComponent() {
	return (
		<div>
			<hr class="descript-user " />
			<br />
			<br />
			<Container>
				<Row className="justify-content-center">
					<Col md={6} sm={12} className="mb-5">
						<br className="d-none d-lg-block " />
						<br className="d-none d-lg-block" />
						<br className="d-none d-lg-block" />
						<br className="d-none d-lg-block" />
						<div className="text-center mt-5 slogan-1 display-4">Professional Cycle Care</div>
					</Col>
					<Col md={6} sm={12}>
						<iframe
							className="youtube-vid"
							src="https://www.youtube.com/embed/oE3e-jKLKMc?autoplay=0&mute=1&player=html5"
							frameborder="0"
						/>
					</Col>
				</Row>
			</Container>
			<Container>
				<Row>
					<Col>
						<div className="text-center mt-3 slogan-2 font-weight-bold">
							Use our online service or whatsapp facility to schedule your cycle service.Also we would love to host you for a coffee/Tea
							at our state of the art service centres.
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default CycleCareComponent;

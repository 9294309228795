import React, { useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

import Col from 'react-bootstrap/Col';
import firebase from '../infocomponents/firebase';
import { Link } from 'react-router-dom';

function UserNavComponent() {
	const [ userName, setUser ] = useState('LOGIN');
	const [ loginStatus, setStatus ] = useState('');

	function signOutUser() {
		firebase
			.auth()
			.signOut()
			.then(function() {
				console.log('Sign-out successful');
			})
			.catch(function(error) {
				console.log('An error happened');
			});
	}
	firebase.auth().onAuthStateChanged(function(resultUser) {
		if (resultUser) {
			console.log('USER LOGGED IN');
			setStatus('SignOut');
			let user = resultUser.email;
			if (user != null) setUser(`${user.substr(0, user.indexOf('@'))}`);
			else setUser(`Hi, ${resultUser.phoneNumber}`);
		} else {
			// No user is signed in.
			console.log('USER NOT LOGGED IN');
			setUser('LOGIN');
			setStatus('');
		}
	});
	return (
		<div className="bg-user">
			<Navbar bg="transparent" expand="md">
				<Navbar.Brand className="logo-name ">
					
					<a class="navbar-brand" href="/home">
                        <img src="cycleworxin.png" alt="" width="300" height="50"/>
                    </a>

				</Navbar.Brand>
				<Navbar.Toggle aria-controls="basic-navbar-nav" />
				<Navbar.Collapse id="basic-navbar-nav" className="justify-content-end mr-5 ">
					<div>
						<Nav className="mr-auto">
							<Nav.Link className="mr-2 active ">
								<Link to="/home" style={{ textDecoration: 'none' }} className="text-dark">
									HOME
								</Link>
							</Nav.Link>
							<Nav.Link className="mr-2 ">
								<Link to="/service" style={{ textDecoration: 'none' }} className="text-dark">
									SERVICE
								</Link>
							</Nav.Link>
							<Nav.Link className="mr-2 ">
								<Link to="/booking" style={{ textDecoration: 'none' }} className="text-dark">
									BOOKING
								</Link>
							</Nav.Link>
							<Nav.Link className="mr-2 ">
								{userName === 'LOGIN' ? (
									<Link to="/login" style={{ textDecoration: 'none' }} className="text-dark">
										{`Hi, ${userName}`}
									</Link>
								) : (
									<Link to="/service" style={{ textDecoration: 'none' }} className="text-dark">
										{`Hi, ${userName}`}
									</Link> //To be made.
								)}
							</Nav.Link>
							<Nav.Link className="mr-2 ">
								<Link to="/faq" style={{ textDecoration: 'none' }} className="text-dark">
									FAQ
								</Link>
							</Nav.Link>
							<Nav.Link className="mr-2 ">
								<Link
									to="/"
									style={{ textDecoration: 'none' }}
									className="text-dark"
									onClick={signOutUser}
								>
									{loginStatus}
								</Link>
							</Nav.Link>
						</Nav>
					</div>
				</Navbar.Collapse>
			</Navbar>
			<hr class="descript-user mr-5" />
			<Container fluid>
				<Row className="box-user row align-items-center">
					<Col lg="1" />
					<Col lg="8">
						<h1 className=" text-dark slogan-1  ">
							Hello ! <small>{userName}</small>
						</h1>
					</Col>
				</Row>
			</Container>
		</div>
	);
}

export default UserNavComponent;

import React from 'react';
import UserNavComponent from './userdashboardcomponents/UserNavComponent';
import firebase from './infocomponents/firebase';
import DashboardBodyComponent from './userdashboardcomponents/DashboardBodyComponent';

function UserDashboardComponent(props) {
	firebase.auth().onAuthStateChanged(function(resultUser) {
		var firebaseUser = firebase.auth().currentUser;
		if (firebaseUser) {
			console.log('USER LOGGED IN');
		} else {
			// No user is signed in.
			console.log('USER NOT LOGGED IN');
			props.history.push('/login');
		}
	});
	return (
		<div className="bg-user">
			<UserNavComponent />
			<br />
			<DashboardBodyComponent />
			<br />
			<br />
		</div>
	);
}

export default UserDashboardComponent;
